import { CardItem, ContentItem } from 'types/content-types'

export const IntroCardData: CardItem[] = [
	{
		title: 'Features',
		content: [
			'Explores and tests any web app out-of-the-box',
			'Verifies pages, links, buttons, and forms',
			'Supports configurable business rules',
			'Reads and enforces form specifications',
			'Verifies accessibility (WCAG and Section 508)',
			'Performs end-to-end performance testing',
			'Runs from any browser'
		],
		icon: 'assets/images/landing/img-feature1.png'
	},
	{
		title: 'Benefits',
		content: [
			'Faster: up to 100x faster than manual testing',
			'Cheaper: eliminates the need to develop many automation scripts',
			'Quicker: enables new functions, modules, or entire applications to be tested immediately',
			'Better: provides objective test coverage statistics and frees testers to focus on high-value tests'
		],
		icon: 'assets/images/landing/img-feature2.png'
	},
	{
		title: 'Uses',
		content: ['Basic functional testing', 'Smoke testing', 'Regressing testing', 'Accessibility testing', 'Performance testing'],
		icon: 'assets/images/landing/img-feature3.png'
	}
]

export const IntroContentData: ContentItem = {
	title: '',
	content:
		'ASTA is the first tool to perform fully autonomous testing of enterprise web applications. No scripting required! ASTA explores and tests your web applications using configurable rules to verify functionality, accessibility, and performance. It eliminates the need for manual scripting for many test cases and enables testers to shift their efforts to more value-added activities.'
}

export const EnterpriseCardData: CardItem[] = [
	{
		title: 'Efficient Coverage',
		content: [
			'ASTA builds a model of your application and uses it to optimize testing. The agent uses the model to remember what has already been tested and to seek out untested pages and components. The result is better test coverage in less time. You also get a meaningful measure of test coverage in terms of the percentage of application functionality tested. Test every part of every build and deploy with confidence.'
		]
	},
	{
		title: 'Comprehensive Testing',
		content: [
			'ASTA uses generalized rules to check for correct behavior across your entire website. The agent will identify and verify the behavior no matter where it is. The agent also generates both valid and invalid data to ensure every form handles both correctly. No more drudgery of creating and maintaining hundreds of test scripts to check basic functionality...the agent does the work for you.'
		]
	},
	{
		title: "You're In Control",
		content: [
			'Need to test a particular scenario? ASTA allows you to direct the test agent using custom flows, rules, and data. Want to focus testing on a particular component? Drop it on the work queue and let the agent take care of it. Mix and match autonomous testing and directed testing however you want.'
		]
	}
]

export const EnterpriseContentData: ContentItem = {
	title: 'Enterprise-Grade Testing Features',
	content:
		'ASTA was built to test large, complex, enterprise web applications. It has been used successfully on web applications with hundreds of forms and millions of users.'
}

export const StepCardData: CardItem[] = [
	{
		title: 'Enter the application URL',
		content: [
			'Enter the base URL of your application and click "Start". The agent will crawl and test your application until it is done.'
		],
		icon: 'assets/images/landing/one.png'
	},
	{
		title: 'Watch it run (or not!)',
		content: [
			'The "Status" page provides an overview of the testing in progress, including the number of components found and the percentage tested. It also displays a screenshot of the page currently being tested.'
		],
		icon: 'assets/images/landing/two.png'
	},
	{
		title: 'View results',
		content: [
			'Select the "Model" view to show the test results for every page and component of your application. Review the summary statistics, and drill down for error details.'
		],
		icon: 'assets/images/landing/three.png'
	}
]

export const StepContentData: ContentItem = {
	title: "It's as Easy as 1-2-3...",
	content: 'Get started easily, and up your testing capability immediately. No need to learn a new tool or scripting language.'
}

export const SupportData: CardItem[] = [
	{
		title: 'Technical Documentation',
		content: ['Read the ASTA user and technical guides.'],
		icon: 'assets/images/landing/img-feature1.svg',
		href: '/docs'
	},
	{
		title: 'Request Support',
		content: ['Submit a support request via email.'],
		icon: 'assets/images/landing/img-feature2.svg',
		href: 'mailto:support@bpsconsulting.com?subject=ASTA%20support%20request'
	},
	{
		title: 'Ask the Community',
		content: ['COMING SOON - Ask the ASTA user community for help or information.'],
		icon: 'assets/images/landing/img-feature3.svg'
	}
]

export const PricingContentData: ContentItem = {
	title: 'ASTA Pricing',
	content:
		'ASTA pricing is designed to be simple and transparent. The Plus and Pro versions are priced per-application. The Enterprise version includes tailoring ASTA to your application and environment, live training, and phone support. We offer a full customer satisfaction guarantee: we will provide a full refund if ASTA does not deliver the value that you expected.'
}
